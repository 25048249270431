export const BASE_URL = window.location.protocol + "//" + window.location.host;
export const STORY_BASE_URL = 'https://story.uniqlo-jp.life';


const Endpoint = {
    POST_TO_ADDRESS: '/api/index/address',
    ME: '/user/getUserinfo',
    
    STORY_DETAIL: '/index.php/api/Tiezi/getTiezi',
    STORY_COMMENT_LIST: '/index.php/api/Pinglun/getPinglunlisttiezi',

    UPLOAD: '/api/common/upload',
    ORDER: '/api/index/index',
    ORDER_DETAIL: '/api/index/getOrderInfoByOrderNo',
    ORDER_UPDATE: '/api/index/updateOrder',
    ORDER_STATUS_UPDATE: '/api/index/customstatus',
    ORDER_SHARE_STATUS: '/api/index/updateOrderShareStatus',
    TRANSLATION: '/api/Urlrelation/getUrlrelationUrl',
    PIXEL: '/api/Urlrelation/getUrlrelationUrl',
    FB_CONVERSION_EVENT: '/api/facebook/EventsAll',
    CUSTOM_EVENT: '/api/Events/addevent',
}
export default Endpoint;

export const DEFAULT_PIXEL_ID = '728415222800127';
export const DEFAULT_FBQ_TOKEN = 'EAAQkAJNepdoBO0aVtiBpDkZBHL3x4M8mJHHiCmvrmebGZA1mYT8mUWu1HaaFHYIOeAtZCynHjZC52MDmy9W4ALK5Ym6UtespgEYiXuV8jYU3VtDZCrIwAsLO0X8lWy1JpnSS8rjLwndcZBAMZC84VpfPjqOXK7UJIIGaWGK3Iu9OpQVbGRjdsffeOaNFXDj3q38awZDZD';
